import React, { useState } from 'react';
import Layout   from '../components/common/layout';
import Footer   from '../components/common/Footer';
import img1 from "../images/gift-card.svg";
import img2 from "../images/lite.svg";
import img3 from "../images/super.svg";
import img4 from "../images/wonder.svg";
import Header from '../components/common/Header';
import HireForm from '../components/common/hireForm';
import { Helmet } from "react-helmet";
import '../assets/sass/offers.scss';
import SingleOffer from '../components/common/singleOffer'
const Offer = () => {
    const [offer,selectOffer] = useState(true);
    const [wtLimit,setwtLimit] = useState('');
    const [offername,setoffername] = useState('');
    const [price,setprice] = useState('');
    const [noofOrder,setnoofOrder] = useState('');
    const [productimage,setproductimage] = useState('');
    
    function selected() {
        console.log('Again');
        window.scrollTo(0,81)
        setwtLimit('1')
        setprice('$45.99')
        setnoofOrder(1)
        setoffername('Lite Wash')
        setproductimage('img2')
        selectOffer(!offer)
    }
    function selected2(){
        window.scrollTo(0,81)
        setwtLimit('2')
        setprice('$75.99')
        setnoofOrder(2)
        setoffername('Super Wash')
        setproductimage('img3')
        selectOffer(!offer)
    }
    function selected3(){
        window.scrollTo(0,81)
        setwtLimit('3')
        setprice('$115.99')
        setnoofOrder(3)
        setoffername('Wonder Wash')
        setproductimage('img4')
        selectOffer(!offer)
    }
    return(
      <Layout>
        <Header />
        <Helmet>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />       
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
            <meta name="author" content="LaundryToGo - We Make Laundry Easy" />
            <meta name="description" content="Set Someone Free from doing laundry this holiday season." />
            <title>LaundryToGo - Set Someone Free</title>
        </Helmet>
        {   offer ? 
        <div id="wrapper" style={{color: "#000"}}>
        {/* Start */}
        <div className="Offer_wrapper">
            <div className="container-fluid Offer_container">
                    <div className="row">
                        <div className={'Offer_topPageImageDiv'}>
                            <img className={'Offer_topPageImage'} src={img1} alt ="Partner Image" />
                        </div>
                        <br/>
                        <div className={'col-md-12 colmTextHeading'}>
                            <h1>Laundrytogo Gift Card</h1>
                            <p className="offer-top-heading-desktop">Gift the same unmatched experience to your loved ones.</p>
                            <p className="offer-top-heading-mobile">Gift the same unmatched experience to your loved ones.</p>
                            {/* <p>Gift the same unmatched experience to your loved ones.</p> */}
                            {/* <span className="offer-top-heading-desktop">A perfect gift for friends and family</span>
                            <span className="offer-top-heading-mobile">A perfect gift for friends and family</span> */}
                        </div>
                        <div className={'col-md-12 offer-mobile-wrapper'}>
                            <div className={'row offers-three-colmswrapper'}>
                                <h2 className={'Offer-Chose-Coupon'}>Choose your coupon</h2>
                                <p className={'Offer-Chose-question'}>Have questions? <a href="tel:+16477224585">Call us.</a></p>
                                <div className={'col-md-4 col-sm-12 Offer_col1 Offer_col-single'}>
                                    <img src={img2} className={'Offer_col-single-img'} />
                                    <div className={'Offer-Col-Content'}>
                                        <div className={'Offer-inner-conten-border'}>
                                            <h3 className={'offer-product-name'}>Lite Wash</h3>
                                            <p>Coupon valid for 1 garbage bag</p>
                                            <p className={'offers-includes'}>Includes:</p>
                                            <ul>
                                                <li>Door Pick up</li>
                                                <li>Wash, Dry and Fold</li>
                                                <li>Sort</li>
                                                <li>Door Drop-off</li>
                                            </ul>
                                            {/* <p>1 full garbage bag</p> */}
                                            <br/>
                                            <h4 className={'Offer-col-price'}><b>$45.99</b></h4>
                                            <div className={'btn-offer'} onClick={selected}>
                                                Select 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-md-4 col-sm-12 Offer_col2 Offer_col-single'} >
                                    <img src={img3} className={'Offer_col-single-img'}  />
                                    <div className={'Offer-Col-Content'}>
                                        <div className={'Offer-inner-conten-border'}>
                                            <h3 className={'offer-product-name'}>Super Wash</h3>
                                            <p>Coupon valid for 2 garbage bags</p>
                                            <p className={'offers-includes'}>Includes:</p>
                                            <ul>
                                                <li>Door Pick up</li>
                                                <li>Wash, Dry and Fold</li>
                                                <li>Sort</li>
                                                <li>Door Drop-off</li>
                                            </ul>
                                            {/* <p>1 full garbage bag per order</p> */}
                                            <br/>
                                            <h4 className={'Offer-col-price'}><b>$75.99</b></h4>
                                            <div className={'btn-offer'} onClick={selected2}>
                                                Select
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-md-4 col-sm-12 Offer_col3 Offer_col-single'}>
                                    <img src={img4} className={'Offer_col-single-img'} />
                                    <div className={'Offer-Col-Content'}>
                                        <div className={''}>
                                            <h3 className={'offer-product-name'}>Wonder Wash</h3>
                                            <p>Coupon valid for 3 garbage bags</p>
                                            <p className={'offers-includes'}>Includes:</p>
                                            <ul>
                                                <li>Door Pick up</li>
                                                <li>Wash, Dry and Fold</li>
                                                <li>Sort</li>
                                                <li>Door Drop-off</li>
                                            </ul>
                                            {/* <p>1 full garbage bag per order</p> */}
                                            <br/>
                                            <h4 className={'Offer-col-price'}><b>$115.99</b></h4>
                                            <div className={'btn-offer'} onClick={selected3}>
                                                Select
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            
                        </div>
                        <p className={'bootomText'}>
                            <br/>
                            <br/>
                            *An invoice will be emailed to you for payment. Once payment is made, the coupon code will be sent to you and the recipient as per your preferred delivery instructions. The coupon can only be used while placing orders at LaundryToGo.ca and will only work for the number of times it is intended. Please refer to the coupons details above.
                            <br/>
                            <br/>
                        </p>
                            </div>
                    </div>
            </div>
        </div>
        {/* End */}
        </div> 
        : <SingleOffer productimage={productimage} price={price} noofOrder={noofOrder} wtLimit={wtLimit} offername={offername} onchangeSelection={selected} />}
        <Footer bg={"white"} />
  </Layout>
    )
}

export default Offer;